<template>
  <div>
    <div class="pc-PersonalCenter">
      <img class="bgimg" src="@/assets/images/bg.png" alt="" />
      <div class="main">
        <div class="left">
          <div class="menu">
            <!-- <div class="userbox" v-if="userInfo">
              <div class="userimg">
                <img
                  v-if="userInfo.avatar"
                  class="headerimg"
                  :src="userInfo.avatar"
                  alt=""
                />
                <img class="logo" src="@/assets/images/userlogo.png" alt="" />
              </div>
              <div class="details" v-if="userInfo">
                <div class="title">{{ userInfo.realName }}</div>
                <div class="vip">{{ userInfo.groupTitle }}</div>
                <div class="timer">
                  企业会员时间：<span>2024.7.22</span> 到期
                </div>
                 <div class="renew" v-if="userInfo.groupTitle">立即续费</div>
                <div class="vipbg" v-else>
                  <div class="vipmssage">
                    <div class="title">塑料圈会员</div>
                    <div class="OpenVIP">开通会员</div>
                  </div>
                </div> 
              </div>
            </div> -->
            <el-menu :default-active="$route.path" router unique-opened>
              <div v-for="item of navList" :key="item.path">
                <el-menu-item :index="item.path" v-if="!item.children">
                   <i :class="item.icon"></i>
                  <span>{{ item.title }}</span>
                </el-menu-item>
                <el-submenu :index="item.path" v-else>
                  <template slot="title">
                      <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                  </template>
                  <el-menu-item
                    v-for="items of item.children"
                    :key="items.path"
                    :index="items.path"
                    >{{ items.title }}</el-menu-item
                  >
                </el-submenu>
              </div>
            </el-menu>
          </div>
        </div>
        <div class="right">
          <div class="container">
            <div class="wrap">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mob-PersonalCenter">
      <div class="mob-u-info" v-if="userInfo">
        <div class="mob-header">
          <div class="left">
            <div class="header-img" v-if="userInfo">
              <img
                class="headerimg"
                :src="userInfo.avatar"
                alt=""
              />
              <img class="logo" src="@/assets/images/userlogo.png" alt="" />
            </div>
            <div class="mob-usermessage" v-if="userInfo">
              <p>{{ userInfo.realName }}</p>
              <div class="mob_logo">
                <img src="../../assets/images/mob_logo.png" alt="" />
                <span> {{ userInfo.groupTitle }}</span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="renew" v-if="userInfo.groupTitle">立即续费</div>
            <div class="timer">企业会员时间：<span>2022.7.22</span> 到期</div>
          </div>
        </div>
        <div class="items" v-for="(item, index) in mobNavList" :key="index">
          <div>
            <i class="iconfont" :class="item.icon"></i> &nbsp; {{ item.title }}
          </div>
          <div>
            <!-- <router-link :to="item.path"> -->
            <i
              class="iconfont icon-xiangyoujiantou"
              @click="itemgo(item.path)"
            ></i>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWxloginin,getuserinfo } from "@/api";
export default {
  name: "PersonalCenter",
  data() {
    return {
      navList: [
        {
          title: "基础信息",
          icon:"el-icon-user-solid",
          path: "/userinfo/basics",
        },
        // {
        //   title: "账户安全",
        //   path: "/userinfo/Accountsecurity",
        // },
        {
          title: "我的供需",
            icon:"el-icon-s-opportunity",
          path: "/userinfo/Mysupply",
        },
        {
          title: "我的收藏",
            icon:"el-icon-star-on",
          path: "/userinfo/MyExpert",
          children: [
            {
              title: "收藏的企业",
              path: "/userinfo/MyCompany",
            },
            {
              title: "收藏的专家",
              path: "/userinfo/MyExpert",
            },
            {
              title: "收藏的产品",
              path: "/userinfo/Myproducts",
            },
          ],
        },
        // {
        //   title: "企业管理",
        //   path: "/userinfo/businessmanagement",
        // },
        // {
        //   title: "我的优惠券",
        //   path: "/userinfo/coupon",
        // },
      ],
      mobNavList: [
        {
          title: "基础信息",
          path: "/userinfo/MobBasics",
        },
        {
          title: "账户安全",
          path: "/userinfo/MobAccountsecurity",
        },
        // {
        //   title: "企业管理",
        //   path: "/userinfo/MobBusinessmanagement",
        // },
        // {
        //   title: "我的优惠券",
        //   path: "/userinfo/MobCoupon",
        // },
      ],
      userInfo: {},
    };
  },
  methods: {
    itemgo(path) {
      if (path !== "/userinfo/MobBasics") {
        this.$message.error("手机版暂为开放");
      } else {
        this.$router.push("/userinfo/MobBasics");
      }
    },
    submitOAuth(provider, code) {
      let href = `${window.location.protocol}//siem-icmlab.ac.cn/index?provider=wechat`;
      let uri = encodeURIComponent(href);
      let data = {
        siteId: 23,
        provider: provider,
        code: code,
        redirectUri: uri,
      };
      getWxloginin(data).then((res) => {
        localStorage.setItem("token", res.data.accessToken);
        console.log(res);
         window.location.href = 'https://siem-icmlab.ac.cn/userinfo/basics';
        
      });
    },
    userinfo() {
      getuserinfo()
        .then((res) => {
          console.log("用户信息", res);
          this.userInfo = res.data
          localStorage.setItem("userName", res.data.userName);
           localStorage.setItem("userId", res.data.userId);
          this.$store.commit("setUserInfo", res);
        })
        .catch((err) => {
          localStorage.setItem("token", "");
          localStorage.setItem("userName", "");
        });
    },
  },
  created() {
    this.userinfo()
    let provider = this.$route.query.provider;
    let code = this.$route.query.code;
    if (provider && code) {
      this.submitOAuth(provider, code);
    }
    // let username = window.localStorage.getItem("userName");
    // if (username) {
    //   getuserinfo()
    //     .then((res) => {
    //       this.userInfo = res.data;
    //       localStorage.setItem("userId", this.userInfo.id);
    //     })
    //     .catch((err) => {
    //       localStorage.setItem("userName", "");
    //       this.userInfo = {};
    //     });
    // } else {
    //   this.$router.push('/login')
    // }
  },
  computed: {
     
    userInfo() {
      console.log("个人中心侧边栏"+ this.$store.state.userInfo);
      // if (sessionStorage.getItem('userInfo')) {
      //   this.$store.replaceState(Object.assign({}, this.$store.state.userInfo, JSON.parse(sessionStorage.getItem('userInfo'))))
      // }
      // window.addEventListener('beforeunload', () => {
      //   sessionStorage.setItem('userInfo', JSON.stringify(this.$store.state.userInfo))
      // })
      // return this.$store.state.userInfo;
    },
  },
};
</script>

<style lang="less" scoped>
.pc-PersonalCenter {
  .bgimg {
    width: 100%;
    height: 200px;
  }
  .main {
    width: 1280px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    margin-top: -10px;
    display: flex;
    .left {
      margin-right: 20px;
      .menu {
        width: 279px;
        height: 720px;
        // margin-top: -70px;
        background-color: white;
        .userbox {
          position: relative;
          border-bottom: 5px solid #f9f9f9;
          .userimg {
            background: white;
            width: 86px;
            height: 86px;
            margin: 0 auto;
            border-radius: 50%;
            margin-top: 0px;
            position: relative;
            top: -37px;
            .headerimg {
              width: 88%;
              border-radius: 50%;
              margin-left: 6%;
              margin-top: 6%;
            }
            .logo {
              position: absolute;
              right: 7px;
              bottom: 5px;
            }
          }
          .details {
            width: 80%;
            text-align: center;
            margin: 0 auto;
            margin-top: -37px;
            margin-bottom: 20px;
            .title {
              font-size: 22px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              margin-top: 7px;
            }
            .vip {
              font-size: 10px;
              color: #6387b8;
              width: 99px;
              height: 21px;
              margin: 0 auto;
              margin-top: 10px;
              line-height: 22px;
              text-indent: 33px;
              background-image: url("../../assets/images/userbg.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
            .timer {
              font-size: 10px;
              color: #b9b9b9;
              font-weight: 400;
              margin-top: 10px;
              span {
                color: #698ebd;
              }
            }
            .renew {
              margin-top: 20px;
              color: white;
              width: 91px;
              height: 26px;
              background: linear-gradient(
                229deg,
                #ff908e 0%,
                #fc3568 48%,
                #a279cd 100%
              );
              font-family: PingFangSC;
              line-height: 26px;
              font-size: 12px;
              border-radius: 6px 6px 6px 6px;
              margin: 0 auto;
              margin-top: 20px;
            }
          }
          .vipbg {
            width: 219px;
            height: 29px;
            background-image: url("../../assets/images/vip.png");
            margin-top: 15px;
            .vipmssage {
              width: 80%;
              height: 100%;
              float: right;
              display: flex;
              .title {
                color: white;
                font-size: 15px;
              }
              .OpenVIP {
                color: #ff5a71;
                font-size: 10px;
                height: 15px;
                line-height: 25px;
                margin-top: 3px;
                padding-left: 10px;
                padding-right: 10px;
                margin-left: 28px;
                border-radius: 11px 11px 11px 11px;
                padding-bottom: 7px;
                background: #ffe7da;
              }
            }
          }
        }
        .user {
          width: 100%;
          padding: 30px 70px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding-bottom: 20px;
          border-bottom: 1px solid #f2f2f2;
          .el-image {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            cursor: pointer;
          }

          p {
            width: 100%;
            font-size: 13px;
            color: #999999;
            text-align: center;
            margin-bottom: 10px;
          }
          .userName {
            margin: 12px 0 24px 0;
            font-size: 15px;
            color: #333333;
            font-weight: bold;
          }
          button {
            width: 107px;
            height: 35px;
            box-sizing: border-box;
            background: linear-gradient(90deg, #55d6e9, #009fd7);
            border-radius: 18px;
            border: none;
            font-size: 15px;
            color: white;
            padding: 11px 23px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
        .el-menu {
          margin-top: 1rem;
          div {
            margin-bottom: 4px !important;
          }
          .el-menu-item,
          .el-submenu {
            // width: 77%;
            margin: 0 auto;
               
            text-align: left;
            color: #333333;
            font-size: 14px;
            font-weight: 600;
            height: 55px;
            span {
              position: relative;
              // top: -3px;
                  // margin-left: 10px;
                  
            
              // vertical-align: top;
            }
            i {
              font-size: 20px;
              color: #c7d4d8;
              margin-right: 10px;
            }
            .el-menu-item {
              min-width: 180px;
            }
          }
          .el-menu-item.is-active {
            color: #314483;
            // background-color: #084bd117;
             border-left: 3px solid #409eff;
            i {
              color: #04a2d8;
            }
          }
        }
      }
    }
    .right {
      background: white;
      width: 100%;
      margin-top: 15px;
    }
  }
}
.mob-PersonalCenter {
  display: none;
}
@media screen and (max-width: 900px) {
  .pc-PersonalCenter {
    display: none;
  }
  .mob-PersonalCenter {
    display: block;
    .mob-u-info {
      width: 100%;
      left: 0;
      display: block !important;
      .mob-header {
        height: 139px;
        background: #f5f9fc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          .header-img {
            margin-right: 20px;
            .headerimg {
              width: 57px;
              border-radius: 50%;
            }
            .logo {
              margin-left: -20px;
            }
          }
          p {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .mob_logo {
            padding-left: 5px;
            padding-right: 5px;
            height: 18px;
            background: white;
            border-radius: 9px;
            font-size: 10px;
            color: #6387b8;
            span {
              position: relative;
              top: -2px;
            }
            img {
              margin-top: 2px;
              margin-left: -3px;
            }
          }
          padding-left: 17px;
        }
        .right {
          margin-right: 20px;
          .renew {
            margin-top: -5px;
            margin-bottom: 10px;
            color: white;
            width: 91px;
            height: 26px;
            background: linear-gradient(
              229deg,
              #ff908e 0%,
              #fc3568 48%,
              #a279cd 100%
            );
            font-family: PingFangSC;
            line-height: 26px;
            font-size: 12px;
            border-radius: 6px 6px 6px 6px;
            text-align: center;
          }
          .timer {
            font-size: 10px;
            color: #b9b9b9;
            font-weight: 400;
            margin-top: 10px;
            span {
              color: #698ebd;
            }
          }
        }
      }
      .items {
        a {
          text-decoration: none;
        }
        .router-link-active {
          text-decoration: none;
        }
        margin-top: 13px;
        background: white;
        padding: 18px 20px;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
      }
    }
  }
}
</style>